
			.background {
				background-color:#333;
				width:100vw;
				height:100vh;
				padding:0;
				margin-left:-25px;
				margin-top:-25px;
				background-size:cover;
				
			}
			.xsre h5 { 
				font-size:20px;
				font-family: 'Nunito', sans-serif;
				color: #3498db;
			}
			.xsre h3 { 
				font-size:20px;
				font-family: 'Nunito', sans-serif;
				color: #3498db;
			}
			.xsreButtonRow{
				margin:0 auto;
				display:block;
				margin-bottom:80px;
			}
			.xsre {
			  font-family:Arial;
			  display:block;
			  margin:0 auto;
			  height:100px;
			  padding-left:25px;
			  padding-right:25px;
			}
			.modalSlider{
				padding:0 25px;
			}
			#xsre .hines h3 {
			    color: #3498db;
			    font-size: 12px !important;
			    text-align: left;
				margin-top:5px;
				font-family: 'Nunito', sans-serif;
				font-weight:700;
				display:inline-block;

			}
			.hines h3 {
			    color: #3498db;
			    font-size: 12px !important;
			    text-align: left;
				margin-top:5px;
				font-family: 'Nunito', sans-serif;
				font-weight:700;
				display:inline-block;

			}
			.hines table td {
				vertical-align: top;
			}
			.hines table {
				table-layout:fixed;
			}
			.hines {
				padding:8px;
			}
			.hines input {
				width:30%;
				vertical-align:right;
			}
			.xsre .btn.sliderbtn {
				float:right;
				padding:5px;
				margin-top:5px;
				margin-right:5px;

				
			}

			.hines .sliderbg {
				height: 100px;
				width: 100%;
				background-size: cover;
				background-position:center;
			}
			
			.xsre .fullwidth {
				width:100%;
				margin-top:20px;
			}
			#xsre_hero{
				border-radius:5px;
			}
			.xsre_table {
				width:100%;
			}
			.xsre .bold {
				font-weight:bolder;
			}
			.xsre .leadform input {
				margin-bottom:10px;
			}
			
			#largeModal .table th, .table td {
				padding-top:0.75rem;
				padding-bottom:0.75rem;
				padding-left:0;
				padding-right:0;
			}

			.hines .switch {
			  position: relative;
			  display: inline-block;
			  width: 38px;
			  height: 23px;
			  margin-bottom:0px;
			  margin-right: 10px;
			}

			.hines .switch input {
			  opacity: 0;
			  width: 0;
			  height: 0;
			}

			.hines .toggler {
			  position: absolute;
			  cursor: pointer;
			  top: 0;
			  left: 0;
			  right: 0;
			  bottom: 0;
			  background-color: #6E6E6E;
			  transition: 0.4s;
			}

			.hines .toggler:before {
			  position: absolute;
			  content: "";
			  height: 15px;
			  width: 15px;
			  left: 4px;
			  bottom: 4px;
			  background-color: white;
			  transition: 0.4s;
			}

			.hines input:checked + .toggler {
			  background-color: #1849AB;
			}

			.hines input:focus + .toggler {
/*			  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);*/
			  outline: none;
			}

			.hines input:checked + .toggler:before {
			  transform: translateX(15px);
			}

			.hines .toggler.round {
			  border-radius: 34px;
			}

			.hines .toggler.round:before {
			  border-radius: 50%;
			}
			.hines .fineprint {
				font-size:8px;
			}
			

			 .xsre .slick-list {
			    margin: 0 -10px;
			 }
			 .xsres_modal {
				 table-layout:fixed;
				 width:100%;
			 }
			 
			 .modalSlider {
				 margin-top: 10px;
			 }
			 .modal-footer .fineprint {
			     font-size:8px;
			     line-height:10px;
			 }
			 div.modalSlider .slick-dots li button:before {
				 font-size:13px;
			 }
			 div#xsre ul.slick-dots, .modalSlider .slick-dots {
				 bottom:20px !important;
			 }
			 
			
	
	
	